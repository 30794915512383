import React from "react";
import { Grid, Typography, Box } from "@mui/material";
import BenefitHealth from "../../assets/images/health insurance-amico.png";
import Sliders from "./Sliders";

const HealthBenefit = () => {
  return (
    <Box
      sx={{
        padding: "40px 20px",
        background: "linear-gradient(135deg, #f8f9fa, #e9ecef)",
        borderRadius: "16px",
        boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
        // maxWidth: "1200px",
        margin: "auto",
        width: "80%",
      }}
    >
      <Grid container spacing={4} alignItems="center">
        {/* Image Section */}
        <Grid item xs={12} sm={5} md={4}>
          <img
            src={BenefitHealth}
            alt="Health Insurance Benefits"
            style={{
              width: "100%",
              height: "auto",
              borderRadius: "12px",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            }}
          />
        </Grid>

        {/* Text Content */}
        <Grid item xs={12} sm={7} md={8}>
          <Typography
            variant="h4"
            sx={{
              marginBottom: "16px",
              fontWeight: "bold",
              color: "#333",
            }}
          >
            Health Insurance{" "}
            <span
              style={{
                color: "#f57c00",
                textDecoration: "underline",
                textDecorationColor: "#f57c00",
              }}
            >
              Benefits
            </span>
          </Typography>

          <Typography variant="body1" paragraph sx={{ color: "#555" }}>
            <strong>Medical Bills:</strong> Coverage against medicinal expenses
            incurred, including pre- and post-hospitalization.
          </Typography>

          <Typography variant="body1" paragraph sx={{ color: "#555" }}>
            <strong>Pre-existing Diseases:</strong> Coverage for any
            pre-existing disease is provided to you after a certain waiting
            period.
          </Typography>

          <Typography variant="body1" paragraph sx={{ color: "#555" }}>
            <strong>Claim Reimbursement:</strong> Coverage for expenses incurred
            for hospitalization due to a medical condition.
          </Typography>

          <Typography variant="body1" paragraph sx={{ color: "#555" }}>
            <strong>Tax Rebate:</strong> Annual premium paid for health coverage
            is subject to tax exemption under Section 80D of the ITA, 1961. Tax
            exemption ranges from ₹25,000 to ₹75,000.
          </Typography>

          <Typography variant="body1" paragraph sx={{ color: "#555" }}>
            <strong>Other Benefits:</strong> As an innovative feature, OPD
            expenses are now covered under a few insurer plans and don’t require
            hospitalization for claim reimbursement. Standalone OPD plans are
            also available in the market.
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default HealthBenefit;
