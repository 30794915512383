import { Box, Grid, Typography } from "@mui/material";
import Stepper from "../../components/Stepper/Stepper";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import AgeForm from "./forms/AgeForm";
import MemberForm from "./forms/MemberForm";
import PersonalForm from "./forms/PersonalForm";
import injuryamico from "./../../assets/images/injuryamico.png";
import ExistingIllness from "./forms/ExistingDisease";
import HealthBenefit from "./BenefitsPage";
import Sliders from "./Sliders";

const ActiveForm = () => {
  const { activeStep } = useTypedSelector((state) => state.landing);
  switch (activeStep) {
    case 0:
      return <MemberForm />;
    case 1:
      return <AgeForm />;
    case 2:
      return <ExistingIllness />;
    case 3:
      return <PersonalForm />;
    default:
      return null; // Avoid rendering an empty fragment
  }
};

const Landing = () => {
  const { activeStep } = useTypedSelector((state) => state.landing);

  return (
    <Grid container spacing={2}>
      {/* Left Section */}
      <Grid item xs={12} md={7}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            minHeight: "520px",
            padding: "30px",
            gap: "30px",
          }}
        >
          <Stepper activeStep={activeStep} />
          <ActiveForm />
        </Box>
      </Grid>

      {/* Right Section */}
      <Grid
        item
        xs={12}
        md={5}
        sx={{
          backgroundColor: "#F05B23",
          padding: "50px",
          color: "#FFFFFF",
          textAlign: "center",
          borderBottomLeftRadius: { xs: "0", md: "30px" },
          borderBottomRightRadius: { xs: "0", md: "30px" },
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          src={injuryamico}
          alt="Insurance Info"
          style={{ width: "90%", maxWidth: "440px" }}
        />
        <Box
          sx={{
            backgroundColor: "white",
            boxShadow: "inset 0px 8px 10px rgba(0, 0, 0, 0.2)",
            padding: "30px",
            borderRadius: "10px",
            textAlign: "left",
            marginTop: "20px",
            width: "100%",
            maxWidth: "600px",
            marginLeft:"30px"
          }}
        >
          <Typography
            variant="body1"
            sx={{
              fontSize: "18px",
              fontWeight: "bold",
              lineHeight: 1.8,
              color: "#000",
              textAlign: "center",
            }}
          >
            Health insurance is not just a financial safety net; it's a promise
            of peace of mind, access to care, and the support you need to live a
            healthy, worry-free life.
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box>
          <Sliders />
        </Box>
      </Grid>
      {/* Health Benefit Section */}
      <Grid item xs={12}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "800px",
          }}
        >
          <HealthBenefit />
        </Box>
      </Grid>
      {/* Sliders Section */}
    </Grid>
  );
};

export default Landing;
