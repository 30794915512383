import { AppBar, Box, Button, Grid, Toolbar } from "@mui/material";
import logo from "../../assets/images/logo.png";
import support from "../../assets/images/support.png";
import "@fortawesome/fontawesome-free/css/all.min.css";

const Header = () => {
  return (
    <AppBar
      position="static"
      sx={{
        background: "linear-gradient(to right, transparent 58%, #F05B23 42%)",
        boxShadow: "none",
      }}
    >
      <Toolbar
        sx={{
          padding: "0 !important",
        }}
      >
        <Grid container alignItems="center" spacing={2}>
          {/* Logo Section */}
          <Grid item xs={6} sm={4} md={7}>
            <Box
              display="flex"
              alignItems="center"
              sx={{
                "& img": {
                  width: {
                    xs: "70px",
                    sm: "80px",
                    md: "90px",
                  },
                },
                marginLeft: {
                  xs: "20px",
                  sm: "30px",
                  md: "50px",
                },
              }}
            >
              <img src={logo} alt="Yella Insure" />
            </Box>
          </Grid>

          {/* Navigation Links and Buttons Section */}
          <Grid item xs={6} sm={8} md={5}>
            <Box
              display="flex"
              justifyContent={{ xs: "center", md: "flex-end" }}
              gap={{ xs: "10px", sm: "20px", md: "50px" }}
              marginRight={{ sm: "4%", md: "6%" }}
              flexWrap="wrap"
            >
              {/* Navigation Links */}
              <Button
                href="https://www.yellainsure.com/"
                sx={{
                  color: "#FFFFFF",
                  textTransform: "none",
                  fontSize: {
                    xs: "10px",
                    sm: "12px",
                    md: "16px",
                  },
                  "&:hover": {
                    backgroundColor: "#FF8C69",
                  },
                  borderRadius: "20px",
                }}
              >
                Home
              </Button>
              <Button
                href="about"
                sx={{
                  color: "#FFFFFF",
                  textTransform: "none",
                  fontSize: {
                    xs: "10px",
                    sm: "12px",
                    md: "16px",
                  },
                  "&:hover": {
                    backgroundColor: "#FF8C69",
                  },
                  borderRadius: "20px",
                }}
              >
                About
              </Button>

              {/* Contact Button */}
              <Button
                variant="contained"
                href="tel:+919288009111"
                sx={{
                  backgroundColor: "#FFA07A",
                  color: "#FFF",
                  fontSize: {
                    xs: "10px",
                    sm: "12px",
                    md: "16px",
                  },
                  "&:hover": {
                    backgroundColor: "#FF8C69",
                  },
                  borderRadius: "20px",
                }}
                startIcon={
                  <>
                    <img
                      width={"20px"}
                      height={"20px"}
                      src={support}
                      alt="support"
                    />
                  </>
                }
              >
                +91 9288009111
              </Button>

              {/* Login Button */}
              <Button
                href="https://account.vkover.com"
                sx={{
                  backgroundColor: "#FFA07A",
                  color: "#FFFFFF",
                  textTransform: "none",
                  fontSize: {
                    xs: "10px",
                    sm: "12px",
                    md: "16px",
                  },
                  "&:hover": {
                    backgroundColor: "#FF8C69",
                  },
                  borderRadius: "20px",
                  width: {
                    xs: "90px",
                    sm: "100px",
                    md: "120px",
                  },
                }}
              >
                <i className="fas fa-user" style={{ marginRight: "8px" }}></i>
                Login
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
