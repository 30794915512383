import { Edit } from "@mui/icons-material";
import { Button, Grid, Typography } from "@mui/material";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { getMembers } from "../../../utils/familyUtils";
import {
  setActiveStep,
  setDiseaseDetails,
} from "../../../modules/landing.slice";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { useGetChronicDiseasesQuery } from "../../../services/Landing.service";
const illness = [
  "Diabetes",
  "Blood Pressure",
  "Surgery",
  "Thyroid",
  "Asthama",
  "Other Disease",
];
const ExistingIllness = () => {
  const { members, disease_details } = useTypedSelector(
    (state) => state.landing
  );
  const dispatch = useDispatch();
  const [disease, setDisease] = useState<number[]>(disease_details);
  const { diseases } = useGetChronicDiseasesQuery();
  return (
    <>
      <Grid container mb={2} width={{ sm: "480px", xs: "100%" }} spacing={2}>
        <Grid item xs={12} mb={"10px"}>
          <Typography
            component="p"
            fontSize="16px"
            fontWeight="400"
            align="center"
            color={"black"}
          >
            Buying Insurance for :{" "}
            <Button
              variant="text"
              onClick={() => {
                dispatch(setActiveStep(0));
              }}
            >
              {getMembers(members)}
              <Edit
                sx={{
                  heigth: "16px",
                  width: "16px",
                  marginLeft: "8px",
                }}
              />
            </Button>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" fontWeight={"500"} color="text.secondary">
            Do any member(s) have any exising illness for which they take
            regular medication?
          </Typography>
        </Grid>
        {diseases?.map((item, index) => {
          return (
            <>
              <Grid item xs={6}>
                <input
                  className="custom_newcheckbox"
                  type="checkbox"
                  id={item.disease.replace(/\s/g, "")}
                  checked={disease.includes(item.id)}
                  onChange={() => {
                    if (disease.includes(item.id)) {
                      setDisease(
                        disease.filter((innerItem) => innerItem !== item.id)
                      );
                    } else {
                      setDisease([...disease, item.id]);
                    }
                  }}
                />
                <label
                  className="custom_newcheckbox_label"
                  htmlFor={item.disease.replace(/\s/g, "")}
                >
                  {item.disease}
                </label>
              </Grid>
            </>
          );
        })}
        <Grid item xs={6}>
          <input
            className="custom_newcheckbox"
            type="checkbox"
            id={`disease_clear`}
            name={`disease_clear`}
            checked={!disease.length}
            onChange={() => {
              setDisease([]);
            }}
          />
          <label className="custom_newcheckbox_label" htmlFor={`disease_clear`}>
            None of these
          </label>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            mb: 2,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            onClick={() => {
              dispatch(setActiveStep(3));
              dispatch(setDiseaseDetails(disease));
            }}
            variant="contained"
            size="large"
            sx={{
              backgroundColor: "#EF6633",
              color: "white",
              boxShadow: "0px 4px 9px rgba(0, 0, 0, 0.02)",
              "&:hover": {
                backgroundColor: "#f64300",
                color: "white",
              },
            }}
          >
            Continue
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default ExistingIllness;
