import { useMutation, useQueryClient } from "@tanstack/react-query";
import api from "../api/api";
import useUrlQuery from "../hooks/useUrlQuery";
import { useGetProposalDetailsQuery } from "./Proposal.service";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { useState } from "react";
interface PanCKYCReq {
  pan_no: string | undefined;
  PROPOSAL_ID?: string;
  dob?: string;
  INSURANCE_SLUG?: string;
}
export const useVerifyPanCKYC = () => {
  const { product } = useGetProposalDetailsQuery();
  const queryClient = useQueryClient();
  const { getUrlQuery } = useUrlQuery();
  const proposal_id = getUrlQuery("proposal_id");
  const { data, mutate, mutateAsync, isLoading, reset } = useMutation(
    (data: PanCKYCReq) => {
      return api.post(
        data?.INSURANCE_SLUG === "star"
          ? "api/VerifyStarCKYC"
          : "api/GetHdfcCKYCDetails",
        data
      );
    },
    {
      onSuccess: () => {
        console.log(data);
        queryClient.invalidateQueries(["proposal", proposal_id]);
      },
      onError: (err: any) => {
        if (err?.response?.status === 500) {
          console.log(err);
          if (err?.response?.data?.showpopup) {
            Swal.fire("Alert", err?.response?.data?.message);
          } else {
            toast.error(
              err?.response?.data?.message ||
                "Something went wrong while submitting the proposal"
            );
          }
        } else {
          toast.error("An unexpected error occurred");
        }
      },
    }
  );

  return {
    verifyCKYC: mutateAsync,
    isVerifyCKYC: isLoading,
    resetVerify: reset,
  };
};

// Define the types for the response as described earlier
interface CKYCResponseData {
  verification_status: boolean;
  ckycNumber: number;
  ckycReferenceId: string;
  proposer_address_proof: string;
  proposer_photo: string;
  proposer_form_60: string;
  status: number;
}

interface APIResponse {
  message: string;
  status: number;
  return_data: CKYCResponseData;
}

export const useGenerateCKYC = () => {
  const [returnData, setReturnData] = useState<APIResponse | null>(null);
  const [photo, setPhoto] = useState<APIResponse | null>(null); // State to store the return data

  const { mutateAsync, isLoading } = useMutation(
    async (data: any) => {
      try {
        // Send the request to generate CKYC
        const response = await api.post("api/GenerateStarCKYC", data, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });

        // If successful, return the return_data from the response
        if (response?.data?.return_data) {
          setReturnData(response?.data?.return_data?.proposer_address_proof);
          setPhoto(response?.data?.return_data?.proposer_photo); // Update the state with return_data
          return response.data.return_data; // Return the return_data directly
        } else {
          throw new Error("Invalid response data");
        }
      } catch (error) {
        console.error("API call failed:", error);
        throw error; // Rethrow the error to be handled by the mutation's onError
      }
    },
    {
      onSuccess: (data) => {
        console.log("CKYC generated successfully:", data);
      },
      onError: (err: any) => {
        if (err?.response?.status === 500) {
          if (err?.response?.data?.showpopup) {
            Swal.fire(err?.response?.data?.message);
          } else {
            toast.error(
              err?.response?.data?.message ||
                "Something went wrong while submitting the proposal"
            );
          }
        } else {
          toast.error("An unexpected error occurred");
        }
      },
    }
  );

  return {
    generateCKYC: mutateAsync,
    isGeneratingCKYC: isLoading,
    generateCkycData: returnData,
    photo: photo, // Return the state that holds the return_data
  };
};

export const useUploadCignaDocMutation = () => {
  const { product } = useGetProposalDetailsQuery();
  const { getUrlQuery } = useUrlQuery();
  const proposal_id = getUrlQuery("proposal_id");
  const { mutateAsync } = useMutation((data: any) => {
    return api.post(
      product?.INSURANCE_SLUG?.includes("care")
        ? "api/UploadCareCKYCDoc"
        : "api/UploadCignaCKYCDoc",
      { ...data, proposal_id },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  });
  return {
    upload: mutateAsync,
  };
};

export const useVerifyCareCKYC = () => {
  const queryClient = useQueryClient();
  const { getUrlQuery } = useUrlQuery();
  const proposal_id = getUrlQuery("proposal_id");
  const { mutateAsync, isLoading, reset, data } = useMutation(
    (data: PanCKYCReq) => {
      return api.post("api/VerifyCareCKYC", {
        ...data,
        proposal_id,
      });
    },
    {
      onSuccess: () => {
        // queryClient.invalidateQueries(["proposal", proposal_id]);
      },
    }
  );

  return {
    verifyCareCKYC: mutateAsync,
    isVerifyCareCKYC: isLoading,
    resetVerify: reset,
    ckycData: data?.data,
  };
};
