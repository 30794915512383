import {
  Box,
  Container,
  Grid,
  Paper,
  Stack,
  Typography,
  Card,
  CardMedia,
} from "@mui/material";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import adbirla from "../../assets/images/ABHI-logo.jpg";
import careclassic from "../../assets/images/careclassic.jpg";
import star from "../../assets/images/star.png";
import niva from "../../assets/images/niva_bupa.png";
import hdfcergo from "../../assets/images/hdfcergo.png";
import { Settings } from "@mui/icons-material";

const Sliders = () => {
  const sliderSettings = {
    infinite: true,
    autoplay: true,
    autoplaySpeed: 1000,
    dots: true,
    arrows: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box sx={{ width: "100%", mt: 13, textAlign: "center" }}>
          {/* Centered Header */}
          <Typography
            variant="h4"
            component="h2"
            sx={{
              fontWeight: "bold",
              fontFamily: "Gidole",
              mb: 6,
            }}
          >
            Our Clients
          </Typography>
          <Slider {...sliderSettings}>
            <div style={{ padding: "10px" }}>
              <Card
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  boxShadow:
                    "3px 3px 10px rgba(0, 0, 0, 0.2), -3px -3px 10px rgba(255, 255, 255, 0.5)",
                  borderRadius: 2,
                  width: "250px",
                  height: "150px",
                  padding: "10px",
                  backgroundColor: "#f9f9f9",
                  border: "1px solid #ddd",
                }}
              >
                <CardMedia
                  component="img"
                  alt="Logo"
                  image={adbirla}
                  sx={{
                    width: "80%",
                    height: "auto",
                    objectFit: "contain",
                  }}
                />
              </Card>
            </div>

            {/* Image 2 */}
            <div style={{ padding: "10px" }}>
              <Card
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  boxShadow:
                    "3px 3px 10px rgba(0, 0, 0, 0.2), -3px -3px 10px rgba(255, 255, 255, 0.5)",
                  borderRadius: 2,
                  width: "250px",
                  height: "150px",
                  padding: "10px",
                  backgroundColor: "#f9f9f9",
                  border: "1px solid #ddd",
                }}
              >
                <CardMedia
                  component="img"
                  alt="Logo"
                  image={careclassic}
                  sx={{
                    width: "80%",
                    height: "auto",
                    objectFit: "contain",
                  }}
                />
              </Card>
            </div>

            {/* Image 3 */}
            <div style={{ padding: "10px" }}>
              <Card
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  boxShadow:
                    "3px 3px 10px rgba(0, 0, 0, 0.2), -3px -3px 10px rgba(255, 255, 255, 0.5)",
                  borderRadius: 2,
                  width: "250px",
                  height: "150px",
                  padding: "10px",
                  backgroundColor: "#f9f9f9",
                  border: "1px solid #ddd",
                }}
              >
                <CardMedia
                  component="img"
                  alt="Logo"
                  image={star}
                  sx={{
                    width: "80%",
                    height: "auto",
                    objectFit: "contain",
                  }}
                />
              </Card>
            </div>

            {/* Image 4 */}
            <div style={{ padding: "10px" }}>
              <Card
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  boxShadow:
                    "3px 3px 10px rgba(0, 0, 0, 0.2), -3px -3px 10px rgba(255, 255, 255, 0.5)",
                  borderRadius: 2,
                  width: "250px",
                  height: "150px",
                  padding: "10px",
                  backgroundColor: "#f9f9f9",
                  border: "1px solid #ddd",
                }}
              >
                <CardMedia
                  component="img"
                  alt="Logo"
                  image={niva}
                  sx={{
                    width: "80%",
                    height: "auto",
                    objectFit: "contain",
                  }}
                />
              </Card>
            </div>

            {/* Image 5 */}
            <div style={{ padding: "10px" }}>
              <Card
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  boxShadow:
                    "3px 3px 10px rgba(0, 0, 0, 0.2), -3px -3px 10px rgba(255, 255, 255, 0.5)",
                  borderRadius: 2,
                  width: "250px",
                  height: "150px",
                  padding: "10px",
                  backgroundColor: "#f9f9f9",
                  border: "1px solid #ddd",
                }}
              >
                <CardMedia
                  component="img"
                  alt="Logo"
                  image={hdfcergo}
                  sx={{
                    width: "80%",
                    height: "auto",
                    objectFit: "contain",
                  }}
                />
              </Card>
            </div>
          </Slider>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Sliders;
