import { Edit } from "@mui/icons-material";
import {
  Button,
  FormLabel,
  Grid,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import {
  setActiveStep,
  setPersonalDetails,
} from "../../../modules/landing.slice";
import { getMembers } from "../../../utils/familyUtils";
import * as yup from "yup";
import { allowOnlyNumbers } from "../../../utils/inputUtils";
import { useNavigate } from "react-router-dom";
import { useGetHealthProductMutation } from "../../../services/Landing.service";
const PersonalForm = () => {
  const {
    members,
    pincode,
    mobile_no,
    gender,
    existing_health_plan,
    disease_details,
  } = useTypedSelector((state) => state.landing);
  const dispatch = useDispatch();

  const { getProducts } = useGetHealthProductMutation();
  const {
    submitForm,
    handleChange,
    handleBlur,
    values,
    errors,
    touched,
    setFieldValue,
  } = useFormik({
    initialValues: {
      pincode: pincode,
      mobile_no: mobile_no,
      existing_health_plan,
    },
    onSubmit: (values) => {
      dispatch(setPersonalDetails(values));
      getProducts({
        pincode: values.pincode,
        mobile_no: values.mobile_no,
        members: members,
        lob: "HEALTH",
        policy_type: members.length > 1 ? "F" : "I",
        requested_si: "400000-500000",
        proposer_gender: gender,
        existing_health_plan: values.existing_health_plan,
        disease_details,
      });
    },
    validationSchema: yup.object().shape({
      pincode: yup
        .string()
        .required("Pincode is required")
        .matches(/^[1-9]{1}[0-9]{5}$/, "Please enter a valid pincode"),
      mobile_no: yup
        .string()
        .required("Mobile no is required")
        .matches(/^[5-9]{1}[0-9]{9}$/, "Please enter a valid mobile no"),
    }),
  });
  return (
    <>
      <Grid container mb={2} width={{ sm: "480px", xs: "100%" }} spacing={2}>
        <Grid item xs={12} mb={"40px"}>
          <Typography
            component="p"
            fontSize="16px"
            fontWeight="400"
            align="center"
            color={"black"}
          >
            Buying Insurance for :{" "}
            <Button
              variant="text"
              onClick={() => {
                dispatch(setActiveStep(0));
              }}
            >
              {getMembers(members)}
              <Edit
                sx={{
                  heigth: "16px",
                  width: "16px",
                  marginLeft: "8px",
                }}
              />
            </Button>
          </Typography>
        </Grid>
        <Grid item md={6} sm={6} xs={12}>
          <InputLabel
            sx={{
              color: "black",
              mb: 1,
              fontSize: "15px",
            }}
          >
            Where do you live?
          </InputLabel>
          <TextField
            placeholder="6 Digit Pincode"
            name="pincode"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.pincode}
            onInput={allowOnlyNumbers}
            error={touched && touched.pincode && !!errors.pincode}
            helperText={touched && touched.pincode && errors.pincode}
            fullWidth
            inputProps={{
              maxLength: 6,
            }}
            sx={{
              "& input": {
                fontSize: "14px",
              },
              "& input::placeholder": {
                color: "text.primary",
                opacity: 1,
              },
            }}
          />
        </Grid>
        <Grid item md={6} sm={6} xs={12}>
          <InputLabel
            sx={{
              color: "black",
              mb: 1,
              fontSize: "15px",
            }}
          >
            Where can we reach you?
          </InputLabel>
          <TextField
            placeholder="Your Mobile Number"
            onChange={handleChange}
            onBlur={handleBlur}
            name={"mobile_no"}
            value={values.mobile_no}
            inputProps={{
              maxLength: 10,
            }}
            onInput={allowOnlyNumbers}
            fullWidth
            error={touched && touched.mobile_no && !!errors.mobile_no}
            helperText={touched && touched.mobile_no && errors.mobile_no}
            sx={{
              "& input": {
                fontSize: "14px",
              },
              "& input::placeholder": {
                color: "text.primary",
                opacity: 1,
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{
                      fontSize: "14px",
                    }}
                  >
                    +91
                  </Typography>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <div className="radio-wrapper">
            <Typography variant="body1" fontSize="16px" color={"black"}>
              Do you have existing health plan?
            </Typography>

            <div className="radio-newcontainer">
              <input
                type="radio"
                id="Yes"
                name="existing_health_plan"
                value={"Y"}
                checked={values["existing_health_plan"] === "Y"}
                onChange={(e) => {
                  setFieldValue("existing_health_plan", "Y");
                }}
              />
              <label htmlFor="Yes">Yes</label>
              <input
                type="radio"
                id="No"
                name="existing_health_plan"
                value={"N"}
                checked={values["existing_health_plan"] === "N"}
                onChange={(e) => {
                  setFieldValue("existing_health_plan", "N");
                }}
              />
              <label htmlFor="No">No</label>
            </div>
          </div>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="caption" color={"black"}>
            I hereby authorize Yella to communicate with me on the given number
            for my Insurance needs. I am aware that this authorization will
            override my registry under NDNC.
          </Typography>
        </Grid>
      </Grid>
      <Button
        sx={{
          mb: 2,
          backgroundColor: "#EF6633",
          color: "white",
          boxShadow: "0px 4px 9px rgba(0, 0, 0, 0.02)",
          "&:hover": {
            backgroundColor: "#f64300",
            color: "white",
          },
        }}
        onClick={() => {
          submitForm();
        }}
        variant="contained"
        size="large"
      >
        Continue
      </Button>
    </>
  );
};

export default PersonalForm;
