import { createSlice } from "@reduxjs/toolkit";

const extractFileMetadata = (file: { name: any; size: any; type: any }) =>
  file
    ? {
        name: file.name,
        size: file.size,
        type: file.type,
      }
    : null;

const proposalSlice = createSlice({
  name: "proposal",
  initialState: {
    activeProposal: 0,
    proposalData: {} as any,
  },
  reducers: {
    setActiveProposal: (state, action) => {
      state.activeProposal = action.payload;
    },
    setProposalData: (state, action) => {
      const { ckyc_details } = action.payload;
      const updatedCKYCDetails = {
        ...ckyc_details,
        proposer_address_proof: extractFileMetadata(
          ckyc_details?.proposer_address_proof
        ),
        proposer_photo: extractFileMetadata(ckyc_details?.proposer_photo),
      };

      state.proposalData = {
        ...state.proposalData,
        ...action.payload,
        ckyc_details: updatedCKYCDetails,
      };
    },
  },
});

export default proposalSlice.reducer;
export const { setActiveProposal, setProposalData } = proposalSlice.actions;
